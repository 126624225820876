import loadable from '@loadable/component';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
// import LottieAnimation from '../components/animation';
// import notFound from '../components/animation/animations/404.json';
import Layout from '../components/layout';
import { SectionDescription } from '../components/section-description';
import { SectionHeading } from '../components/section-heading';

const LottieAnimation = loadable(() => import('../components/animation'));

const sectionStyle = {
  padding: '0 0 10vh'
};

const animationStyle = {
  maxWidth: '720px',
  margin: '0 auto'
};

const NotFoundPage = () => {
  const [notFound, setNotFound] = useState<{} | null>(null);

  useEffect(() => {
    fetch('/animations/coming-soon.json').then(async (r) => {
      const json = await r.json();
      setNotFound(json);
    });
  }, []);

  return (
    <Layout pageTitle={'404 Not Found'}>
      <section className={'d-flex flex-column'} style={sectionStyle}>
        <div className="container text-center">
          {notFound && (
            <LottieAnimation
              animation={notFound}
              threshold={0.42}
              loop={true}
              style={animationStyle}
            />
          )}

          <SectionHeading>We couldn't find the page you requested</SectionHeading>
          <SectionDescription>
            The page might have moved around in the shuffle and may have been relocated. You can try
            and <Link to={'/'}>go back home</Link> to find the correct page.
          </SectionDescription>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
